<script setup>
import { computed, defineProps } from "vue";
import UiHls from "./hls.vue";
const props = defineProps({
	shorts: {
		type: Object,
	},
	wstore: {
		type: Object,
	},
});

const extRemove = (subject) => {
	subject = subject.replace(".mp4", "");
	subject = subject.replace(".MP4", "");
	subject = subject.replace(".mov", "");
	subject = subject.replace(".MOV", "");
	if (subject.indexOf("IMG_") > -1) {
		return "";
	}
	return subject;
};
</script>

<template>
	<div v-for="(s, idx) in shorts" class="boxItems">
		<!--		{{ s.isVideo ? "video" : "image" }}-->
		<div
			v-if="!s.isVideo"
			class="pb20"
			:style="{
				paddingBottom: wstore.cardGap,
			}"
		>
			<a :href="s.isProduct ? `/product/detail/${s.isProduct.no}` : `/shorts24_player.html?content_no=${s.no}`">
				<div class="produtImage" style="background-color: #eaeaea">
					<img
						:src="s.isProduct.product_image_url"
						alt=""
						class="produtImage"
						style="background-color: #eaeaea"
						loading="lazy"
					/>
				</div>
				<div
					class="contentTitle"
					:style="{
						fontSize: wstore.mobile ? '14px' : wstore.titleFontSize,
					}"
				>
					<span v-html="s.isProduct.product_name"></span>
				</div>
			</a>
		</div>
		<div
			v-else
			class="pb20"
			:style="{
				paddingBottom: wstore.cardGap,
			}"
		>
			<!--			{{ s.ui.width + "/" + s.ui.height }}-->
			<a :href="s.isProduct ? `/product/detail/${s.isProduct.no}` : `/shorts24_player.html?content_no=${s.no}`">
				<div
					class="showimages"
					style="background-color: #eaeaea"
					:style="{
						aspectRatio: s.ui.width > 0 && s.ui.width == s.ui.height ? `1/1` : `9/16`,
					}"
				>
					<UiHls
						:hlsUrl="s.ui.content_url"
						:autoplay="true"
						:muted="true"
						:aspectRatio="s.ui.width > 0 && s.ui.width == s.ui.height ? `1/1` : `9/16`"
						:poster="s.ui.thumb_url"
						:id="`card_${s.no}`"
						:start="3000"
						:end="8000"
						:loop="true"
					/>
				</div>

				<div
					class="contentTitle"
					:style="{
						fontSize: wstore.mobile ? '14px' : wstore.titleFontSize,
					}"
				>
					<div v-if="s.isProduct && extRemove(s.ui.subject) != s.isProduct.product_name">
						<div v-html="s.isProduct.product_name" style="padding-bottom: 5px"></div>
						<div v-html="extRemove(s.ui.subject)" style="color: #9c9c9c; font-size: 0.9em"></div>
					</div>
					<div v-else>
						<div v-html="extRemove(s.ui.subject)"></div>
					</div>
				</div>
			</a>
		</div>
	</div>
</template>

<style scoped>
.pb20 {
	padding-bottom: 20px;
}
.produtImage {
	width: 100%;

	object-fit: cover;
	object-position: center;
}
.showimages {
	width: 100%;
	aspect-ratio: 9/16;
	max-height: 660px;
	object-fit: cover;
	object-position: center;
}

@container showcase-main-container  (max-width: 768px) {
	.showimages {
		max-height: 460px;
	}
}
.contentTitle {
	font-size: 14px;
	font-weight: 400;
	padding: 5px 0 5px 0;
	line-height: 1.5;
	max-width: 100%;
	overflow: hidden;
}

.boxItems a {
	color: #000;
	text-decoration: none;
}

.asp16-9 {
	aspect-ratio: 16/9;
}
.asp9-16 {
	aspect-ratio: 9/16;
}
.asp1-1 {
	aspect-ratio: 1/1;
}
</style>
